import React, { lazy, Suspense } from "react";
import { Route, Redirect, BrowserRouter as Router, Switch } from "react-router-dom";
import { Spin } from "antd";
import { useSelector } from "react-redux";

import ErrorBoundary from "./ErrorBoundary";
import { PUBLIC_ROUTE } from "./route.constants";
import Loader from "@iso/components/utility/loader";

import { ConfigProvider } from "antd";
import zh_CN from "antd/es/locale/zh_CN";
import ms_MY from "antd/es/locale/ms_MY";
import en_US from "antd/es/locale/en_US";

const Dashboard = lazy(() => import("./containers/Dashboard/Dashboard"));
const Page404 = lazy(() => import("./containers/Pages/404/404"));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    // component: lazy(() => import("@iso/containers/Pages/SignIn/SignIn")),
    component: lazy(() => import("@iso/containers/EcommerceOutside/Shop/InstantSearch")),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import("@iso/containers/Pages/404/404")),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import("@iso/containers/Pages/500/500")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import("@iso/containers/Pages/SignIn/SignIn")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import("@iso/containers/Member/UnaryRegistration")),
  },
  {
    path: "/signupbinary/:id",
    component: lazy(() => import("@iso/containers/Member/BinaryRegistration")),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() => import("@iso/containers/Pages/ForgotPassword/ForgotPassword")),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() => import("@iso/containers/Pages/ResetPassword/ResetPassword")),
  },
  {
    path: "/Payex/PaymentCancel",
    component: lazy(() => import("@iso/containers/Payex/PaymentCancel")),
  },
  {
    path: "/Payex/PaymentSuccess",
    component: lazy(() => import("@iso/containers/Payex/PaymentSuccess")),
  },
  {
    path: "/SenangPay/PaymentReturn",
    component: lazy(() => import("@iso/containers/SenangPay/PaymentReturn")),
  },
  {
    path: "/testing",
    component: lazy(() => import("@iso/containers/Testing")),
  },

  {
    path: "/shoppingmallCustomer/:sponsorID",
    component: lazy(() => import("@iso/containers/EcommerceOutside/Shop/InstantSearch")),
  },
  {
    path: "/shoppingmallCustomer",
    component: lazy(() => import("@iso/containers/EcommerceOutside/Shop/InstantSearch")),
  },
  {
    path: "/aboutUs",
    component: lazy(() => import("@iso/containers/EcommerceOutside/Shop/AboutUs")),
  },
  {
    path: "/cartCustomer",
    component: lazy(() => import("@iso/containers/EcommerceOutside/Cart/Cart")),
  },
  {
    path: "/productdetailCustomer/:sponsorID/:id",
    component: lazy(() => import("@iso/containers/EcommerceOutside/Shop/ProductDetail")),
  },
  {
    path: "/productdetailCustomer/:id",
    component: lazy(() => import("@iso/containers/EcommerceOutside/Shop/ProductDetail")),
  },
  {
    path: "/productdetailCustomer/:id",
    component: lazy(() => import("@iso/containers/EcommerceOutside/Shop/ProductDetail")),
  },
  {
    path: "/checkoutCustomer",
    component: lazy(() => import("@iso/containers/EcommerceOutside/Checkout/Checkout")),
  },
  {
    path: "/summaryCustomer",
    component: lazy(() => import("@iso/containers/EcommerceOutside/Checkout/Summary")),
  },
  {
    path: "/paymentInvoiceOutside/:id",
    component: lazy(() => import("@iso/containers/Member/RegInvoice/PaymentInvoiceOutside")),
  },
];
function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = useSelector((state) => state.Auth.idToken);
  return (
    <Route
      {...rest}
      render={
        ({ location }) =>
          isLoggedIn ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: location },
              }}
            />
          )
        // <Redirect
        //   to={{
        //     pathname: "/shoppingmallCustomer",
        //     state: { from: location },
        //   }}
        // />
      }
    />
  );
}

export default function Routes() {
  const condition = useSelector((state) => state.Loader.condition);
  const { language } = useSelector((state) => state.LanguageSwitcher);
  var languageID = [
    {
      id: "zh",
      language: zh_CN,
    },
    {
      id: "ms",
      language: ms_MY,
    },
    {
      id: "en",
      language: en_US,
    },
  ];

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <ConfigProvider locale={languageID.find((e) => e.id === language.locale).language}>
          <Spin spinning={condition} style={{ maxHeight: "100%" }}>
            <Router>
              <Switch>
                {publicRoutes.map((route, index) => (
                  <Route key={index} path={route.path} exact={route.exact}>
                    <route.component />
                  </Route>
                ))}
                <PrivateRoute path="/dashboard">
                  <Dashboard />
                </PrivateRoute>
                <Route component={Page404} />
              </Switch>
            </Router>
          </Spin>
        </ConfigProvider>
      </Suspense>
    </ErrorBoundary>
  );
}
