import { isServer } from "@iso/lib/helpers/isServer";

const productDatas = [];

function getInitData() {
  let productQuantity = [];
  const products = {};

  let mallType = "3"; // Default value for mallType
  try {
    const value = localStorage.getItem("mallType");
    if (value) {
      mallType = JSON.parse(value);
    }
  } catch (error) {
    console.warn("Error parsing mallType from localStorage:", error);
  }

  if (!isServer) {
    try {
      const cartProductQuantity = localStorage.getItem("cartProductQuantity");
      let cartProducts = localStorage.getItem("cartProducts");

      if (cartProducts && cartProductQuantity) {
        cartProducts = JSON.parse(cartProducts);
        JSON.parse(cartProductQuantity).forEach((product) => {
          const productCode = product.productCode;

          if (!isNaN(product.quantity)) {
            productQuantity.push({
              productCode,
              quantity: parseInt(product.quantity, 10),
            });

            if (cartProducts[productCode]) {
              products[productCode] = {
                ...cartProducts[productCode],
                price: parseFloat(cartProducts[productCode].memberPrice, 10),
              };
            }
          }
        });
      } else {
        productDatas.forEach((product) => {
          productQuantity.push({
            productCode: product.productCode,
            quantity: 1,
          });
          products[product.productCode] = product;
        });
      }
    } catch (error) {
      console.warn("Error processing cart data from localStorage:", error);
    }
  }

  return { productQuantity, products, mallType };
}

export default getInitData();
